import SnackbarCloseButton from '@commons/Snackbar/SnackbarCloseButton'
import Collapse from '@mui/material/Collapse'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Security
window.eval = null

// ? Keeping it top to use it everywhere
Object.byString = function (o, s = '') {
	if (!o) {
		return
	}

	s = s.replace(/\[(\w+)\]/g, '.$1')
	s = s.replace(/^\./, '') // strip a leading dot
	var a = s.split('.')

	for (var i = 0, n = a.length; i < n; ++i) {
		var k = a[i]

		try {
			if (k in o) {
				o = o[k]
			} else {
				return
			}
		} catch (error) {
			return
		}
	}

	return o
}

Object.flatten = function (data) {
	// console.log('data1111111111111111', data)
	if (data?.predecoder) {
		delete data?.predecoder?.hostname
		delete data?.predecoder?.programname
		delete data?.predecoder?.timestamp
		// console.log('data222222222222', data)
		var result = {}
		// eslint-disable-next-line no-inner-declarations
		function recurse(cur, prop) {
			if (Object(cur) !== cur) {
				result[prop] = cur
			} else if (Array.isArray(cur)) {
				// for (var i = 0, l = cur.length; i < l; i++)
				//     recurse(cur[i], prop + "[" + (i + 1) + "]");
				// if (l === 0)
				//     result[prop] = [];
				recurse(cur.join(', '), prop)
				if (cur.length === 0) result[prop] = []
			} else {
				var isEmpty = true
				if (cur.$$typeof) {
					isEmpty = false
					result[prop] = cur
				} else {
					for (var p in cur) {
						isEmpty = false
						recurse(cur[p], prop ? prop + '.' + p : p)
					}
				}
				if (isEmpty && prop) result[prop] = {}
			}
		}
		recurse(data, '')
		result?.predecoder?.hostname ? delete result?.predecoder.hostname : ''
		// console.log('resultttttttttttttttttttttttttttttt', result)
		return result
	} else {
		var result2 = {}
		// eslint-disable-next-line no-inner-declarations
		function recurse(cur, prop) {
			if (Object(cur) !== cur) {
				result2[prop] = cur
			} else if (Array.isArray(cur)) {
				// for (var i = 0, l = cur.length; i < l; i++)
				//     recurse(cur[i], prop + "[" + (i + 1) + "]");
				// if (l === 0)
				//     result2[prop] = [];
				recurse(cur.join(', '), prop)
				if (cur.length === 0) result2[prop] = []
			} else {
				var isEmpty = true
				if (cur.$$typeof) {
					isEmpty = false
					result2[prop] = cur
				} else {
					for (var p in cur) {
						isEmpty = false
						recurse(cur[p], prop ? prop + '.' + p : p)
					}
				}
				if (isEmpty && prop) result2[prop] = {}
			}
		}
		recurse(data, '')
		result2?.predecoder?.hostname ? delete result2?.predecoder.hostname : ''
		// console.log('resultttttttttttttttttttttttttttttt', result2)
		return result2
	}
}

const notistackRef = React.createRef()

const onClickDismiss = (key) => () => {
	notistackRef.current.closeSnackbar(key)
}
// Sentry Integration

if (import.meta.env.VITE_APP_DISABLE_HOT_RELOAD === 'true') {
	window.webpackHotUpdate = () => { }
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<SnackbarProvider
		/**
			 * for showing snackbar notification, it was required to render on a separate tree (see index.html) because app modal was blurring it (z-index, filter nothing works, so it was the last resort) you can checkout the doc here
			 https://www.iamhosseindhv.com/notistack/demos#react-portal
			 */
		domRoot={document.getElementById('react-notification')}
		TransitionComponent={Collapse}
		ref={notistackRef}
		maxSnack={3}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		autoHideDuration={5000}
		preventDuplicate
		action={(key) => <SnackbarCloseButton onClick={onClickDismiss(key)} />}
	>
		<App />
	</SnackbarProvider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

if ('serviceWorker' in navigator) {
	serviceWorker.register()

	navigator.serviceWorker.ready
		.then((registration) => {
			registration.unregister()
		})
		.catch(console.warn)
}
