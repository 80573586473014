import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import FeedbackIcon from '@mui/icons-material/Feedback'
import LocalPlayIcon from '@mui/icons-material/LocalPlay'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PlayForWorkIcon from '@mui/icons-material/PlayForWork'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
// import SettingsIcon from "@mui/icons-material/Settings";
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { logoutUser } from '@redux/auth/slice'
import { messaging } from '@utils/fcm'
import { deleteToken, isSupported } from 'firebase/messaging'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

const MoreVerticalMenu = ({ openSidebar }) => {
	const dispatch = useDispatch()
	const [openMoreMenu, setOpenMoreMenu] = React.useState(null)
	const navigate = useNavigate()
	const location = useLocation()

	const policies = useSelector((state) => state.auth.allRouteNamesByPolicy)

	const handleMenuClose =
		(url, newTab = false) =>
			() => {
				if (url) {
					if (url === 'logout') {
						isSupported()
							.then((supported) => {
								if (supported) deleteToken(messaging)
							})
							.catch((e) => {
								console.warn('FCM not supported: ' + e)
							})

						dispatch(logoutUser())
						navigate(adminRoutesConstant.adminPaths.LoginScreen.url())
						return
					}

					if (newTab) {
						window.open(url, '_blank').focus()
					} else {
						navigate(url)
					}
				}

				setOpenMoreMenu(null)
			}
	const handleMenuOpen = (e) => setOpenMoreMenu(e.target)

	return (
		<>
			<MoreVertIcon
				onClick={handleMenuOpen}
				sx={{
					color: (theme) => theme.palette.ascents.secondary,
					cursor: 'pointer',
					'&:hover, &:focus': {
						color: (theme) => theme.palette.ascents.wash
					},
					transform: openSidebar ? 'rotate(0deg)' : 'rotate(90deg)',
					marginTop: !openSidebar ? 8 : 0
				}}
			/>
			<Menu
				id='moreVerticalMenu'
				anchorEl={openMoreMenu}
				keepMounted
				open={Boolean(openMoreMenu)}
				onClose={handleMenuClose()}
				sx={{
					'& .MuiMenu-paper': {
						backgroundColor: (theme) => theme.palette.ascents.washLight
					},
					'& .MuiListItemIcon-root': {
						minWidth: 36
					},
					'& .Mui-selected': {
						'& .MuiListItemIcon-root, & .MuiListItemText-root': {
							color: (theme) => theme.palette.ascents.base
						}
					}
				}}
				disableAutoFocusItem
			>
				{policies.includes('MyAccountScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.MyAccountScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.MyAccountScreen.url())}
					>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText>My Account</ListItemText>
					</MenuItem>
				)}
				{policies.includes('BillingScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.BillingScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.BillingScreen.url())}
					>
						<ListItemIcon>
							<CreditCardIcon />
						</ListItemIcon>
						<ListItemText>Billing</ListItemText>
					</MenuItem>
				)}
				{policies.includes('InternalTicketScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.InternalTicketScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.InternalTicketScreen.url())}
					>
						<ListItemIcon>
							<LocalPlayIcon />
						</ListItemIcon>
						<ListItemText>My Tickets</ListItemText>
					</MenuItem>
				)}
				{policies.includes('CreatePdfReportScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.CreatePdfReportScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.CreatePdfReportScreen.url())}
					>
						<ListItemIcon>
							<PlayForWorkIcon />
						</ListItemIcon>
						<ListItemText>Reports</ListItemText>
					</MenuItem>
				)}
				{policies.includes('FeedbackScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.FeedbackScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.FeedbackScreen.url())}
					>
						<ListItemIcon>
							<FeedbackIcon />
						</ListItemIcon>
						<ListItemText>Feedback</ListItemText>
					</MenuItem>
				)}
				<MenuItem onClick={handleMenuClose('logout')}>
					<ListItemIcon>
						<MeetingRoomIcon />
					</ListItemIcon>
					<ListItemText>Logout</ListItemText>
				</MenuItem>
			</Menu>
		</>
	)
}

export default MoreVerticalMenu
