export const mandateKeyTypes = {
	NBFC: { queryRule: 'rbi-nbfc', mandateType: 'RBI-NBFC' },
	PAPG: { queryRule: 'rbi_papg', mandateType: 'RBI-PAPG' },
	SAR: { queryRule: 'rbi_sar', mandateType: 'RBI-SAR' },
	ITGRC: { queryRule: 'rbi-itgrc', mandateType: 'RBI-ITGRC' },
	SEBI: { queryRule: 'sebi', mandateType: 'SEBI' },
	CERTIN: { queryRule: 'cert-in', mandateType: 'CERT-IN' },
	IRDAI: { queryRule: 'irdai', mandateType: 'IRDAI' },
	RMIT: { queryRule: 'rmit', mandateType: 'RMiT' },
	UAEIAR: { queryRule: 'uae-iar', mandateType: 'UAE-IAR' },
	GDPR: { queryRule: 'gdpr', mandateType: 'GDPR' },
	HIPAA: { queryRule: 'hipaa', mandateType: 'HIPAA' },
	SEBICS: { queryRule: 'sebi-cs', mandateType: 'SEBI - Cloud Framework' },
	BSP: { queryRule: 'bsp', mandateType: 'BSP' },
	SEBICSCRF: { queryRule: 'sebi-cscrf', mandateType: 'SEBI - CSCRF' },
	DPDP: { queryRule: 'dpdp', mandateType: 'DPDP' },
	NCIIPC: { queryRule: 'nciipc', mandateType: 'NCIIPC' },
	SEBISANR: { queryRule: 'sebi-sanr', mandateType: 'SEBI-SANR' },
	IFSCA: { queryRule: 'ifsca', mandateType: 'IFSCA' }
}
