import TicketSvg from '@assets/icons/ticket.svg'
import Button from '@commons/Button/Button'
import AppModal from '@commons/Modal/AppModal'
import Text from '@commons/Text'
import { Box, IconButton, Tooltip } from '@mui/material'
import GppMaybeTwoToneIcon from '@mui/icons-material/GppMaybeTwoTone';
import { useSnackbar } from 'notistack'
import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@commons/Icon'
import { addRiskRegister } from '@services/riskRegisterService'

const HostRiskCreation = ({ value }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [modelStatus, setModelStatus] = React.useState(false);

    console.log("Value", value?._source?.rule?.description);

    const openModel = (e) => {
        e.stopPropagation()
        setModelStatus(true)
    }

    const closeModel = () => {
        setModelStatus(false)
    }

    const createRisk = (formData) => {
        let formDataTemp = {
            risk_source: `Host: ${formData?._source?.agent?.name}`,
            risk_status: "Open",
            risk_summary: `Risk Type: ${formData?._source?.rule?.mitre?.technique ?? "Unknown"}`,
            risk_description: formData?._source?.rule?.description,
            risk_likelihood: "1",
            risk_impact: "1",
            risk_likelihood_text: "Rare",
            risk_impact_text: "Low",
        }
        const req = addRiskRegister()
        req.api({
            data: formDataTemp
        }).then((response) => {
            if (response) {
                if (!response.error) {
                    enqueueSnackbar('Risk Created Successfully !!!', { variant: 'success' });
                    setModelStatus(false)
                }
                else {
                    enqueueSnackbar('Error occured in saving this record.', { variant: 'error' });
                }
            }
        })
    }

    return (
        <>
            <Tooltip title={'Create Risk'} placement='top'>
                <IconButton padding={'0.375rem 1rem'} onClick={openModel}>
                    <Icon fontSize={22} block ascent='red'>
                        <GppMaybeTwoToneIcon color='danger' />
                    </Icon>
                </IconButton>
            </Tooltip>

            <AppModal
                open={modelStatus}
                onClose={closeModel}
                onClick={(e) => e.stopPropagation()}
                title={'Create Risk'}
                ActionComponent={<Button onClick={() => {
                    createRisk(value)
                }}>Mark as Risk</Button>}
            >
                <Box py={2}>
                    <Text fontWeight="500" fontSize="1.1rem" lineHeight="1.5" mb={2}>
                        Mark this as potential risk?
                    </Text>
                    <Text color="text.secondary" fontSize="0.875rem" fontWeight="350">
                        This will flag the security alert in{' '}
                        <Text
                            fontSize="0.875rem"
                            fontWeight="500"
                            color="#3b24b2"
                            component={Link}
                            to="/risk-register"
                        >
                            Risk Register
                        </Text>{' '}
                        and will be available for further review.
                    </Text>

                </Box>
            </AppModal>
        </>
    )
}

export default HostRiskCreation
