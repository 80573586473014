import { AxiosService } from "@interceptors/axiosInstance";

export const addRiskRegister = (config) => {
    return AxiosService.post(`/rest/v1/risk-register/`, config);
}

export const updateRiskRegister = (risk_id, config) => {
    return AxiosService.put(`/rest/v1/risk-register/${risk_id}/`, config);
}

export const downloadTemplateApi = (config) => {
    return AxiosService.get(`/rest/v1/risk-register/?option=download`, config);
}

export const getRiskRegisterDetails = (config) => {
    return AxiosService.get(`/rest/v1/risk-register/`, config);
}

export const uploadExcelTemplate = (config) => {
    return AxiosService.post(`/rest/v1/risk-upload/upload/`, config);
}

export const correlatedRisk = (config) => {
    return AxiosService.get(`/rest/v1/correlated-risk/`, config);
}

export const riskListCategory = (config) => {
    return AxiosService.get(`/rest/v1/risk-metrics/list_categories/`, config);
}

export const dashboardSummary = (config) => {
    return AxiosService.get(`/rest/v1/risk-metrics/dashboard_summary/`, config);
}
export const categoryMetrics = (config) => {
    return AxiosService.get(`/rest/v1/risk-metrics/category_metrics/`, config);
}
export const riskThreshold = (config) => {
    return AxiosService.get(`/rest/v1/risk-metrics/risk_thresholds/`, config);
}

export const riskMatrix = (config) => {
    return AxiosService.get(`/rest/v1/risk-metrics/risk_matrix/`, config);
}

export const addRiskCategory = (config) => {
    return AxiosService.post(`/rest/v1/risk-category/`, config);
}
export const editRiskCategory = (category_id, config) => {
    return AxiosService.put(`/rest/v1/risk-category/${category_id}/`, config);
}
export const fetchRiskCategory = (config) => {
    return AxiosService.get(`/rest/v1/risk-category/`, config);
}
export const deleteRiskCategory = (category_id, config) => {
    return AxiosService.delete(`/rest/v1/risk-category/${category_id}/`, config);
}