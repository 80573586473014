/* eslint-disable react/no-unknown-property */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */

import ChartCustomTooltipWrapper from '@commons/Charts/ChartCustomTooltipWrapper'
import Text from '@commons/Text'
import {
	colorsForCharts,
	getRandomColor,
	intervalTypes,
	severityColors,
	severityLevel,
	severityLevels,
	severityLevelsQual
} from '@constants/Chart/chartOptions'
import { optionsByAction } from '@constants/analytics'
import { mandateKeyTypes } from '@constants/mandatesConstant'
import { severityByStatus } from '@constants/status'
import { INTEGRATION_RULE_GROUPS } from '@constants/tableConstants'
import { Box } from '@mui/material'
import flatten from 'flat'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { colorManipulator } from './styles'

export const DEFAULT_TIMESTAMP_FORMAT = 'DD MMMM YYYY, h:mm A'
export const DEFAULT_TIMESTAMP_FORMAT_INDUS = 'DD MMMM YYYY, HH:mm:ss'
export const getBaseScreenLayoutMinHeight = (reduceBy = 0) => `calc(100vh - calc(111px) - calc(1.5rem) - calc(16px) - calc(${reduceBy}))`

export const formatDateTime = (
	date = new Date(),
	options = {
		format: DEFAULT_TIMESTAMP_FORMAT
	}
) => {
	return moment
		.utc(date)
		.local()
		.format(options.format || DEFAULT_TIMESTAMP_FORMAT)
}

export const formatDateTimeWithIndusDate = (date = new Date(), options = { format: DEFAULT_TIMESTAMP_FORMAT_INDUS }) => {
	const formattedDateTime = moment
		.utc(date)
		.local()
		.format(options.format || DEFAULT_TIMESTAMP_FORMAT_INDUS)
	return formattedDateTime
}

export const isDate = function (timestamp) {
	const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/
	return regex.test(timestamp)
}

export const limitTheNumberBetween = (num = 0, min = 0, max = 1) => Math.min(max, Math.max(min, num))

/**
 * @desc Labels are used to display the current page name in UI. The function is used to automate the convertion of route names to labels.
 * @param {string} str - Route name in CamelCase without space.
 * @returns {string} - The label seperate with space and removes 'Screen' / 'Page'
 * @example
 * * // returns 'Security Overview'
 * * convertRouteNamesToLabel('SecurityOverviewScreen');
 * ? Regex is heavy operation. Skip if passed empty string
 * * // returns ''
 * * convertRouteNamesToLabel('');
 */
export const convertRouteNamesToLabel = (str = '') =>
	!!str ? str.replace(/(Screen|Page)$/g, '').replace(/([a-z0-9])([A-Z])/g, '$1 $2') : str

/**
 * @desc Used to display number of days in UI.
 * @param {Date} startDate - Must be in proper datetime format
 * @param {Date} endDate - Must be in proper datetime format
 * @returns {integer} - Number of days
 * @example
 * * // returns 2
 * * getDaysDifference(new Date('6 Aug 2021'), new Date('8 Aug 2021'));
 */
export const getDaysDifference = (startDate, endDate) => {
	return Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24))
}

/**
 * @desc Used to validate in date pickers.
 * @param {Date} start - Start date
 * @param {Date} end - End date
 * @returns {boolean}
 * @example
 * * // return false
 * * checkDateIsAfter(new Date('8 Aug 2021'), new Date('6 Aug 2021'))
 */
export const isSameDay = (start, end) => {
	return moment.utc(start).local().isSame(end, 'day')
}

/**
 * @desc Used to validate in date pickers.
 * @param {Date} start - Start date
 * @param {Date} end - End date
 * @returns {boolean}
 * @example
 * * // return false
 * * checkDateIsAfter(new Date('8 Aug 2021'), new Date('6 Aug 2021'))
 */
export const checkDateIsAfter = (start, end) => {
	return new Date(end) > new Date(start)
}
/**
 * @description Used to check if duration between two dates is less than or equal to given days(passed as third param)
 * @param {Date} start -> Start date
 * @param {Date} end -> End date
 * @param {Number} days -> Given number of days to compare against
 * @returns {boolean}
 */
export const validateDurataionIsLessThanGivenDays = (start, end, days) => {
	if (!start || !end) return false

	return getDaysDifference(start, end) <= days
}

/**
 * @param {object} [response={}] -  contains { '\_d\_':{ aggregations:{ [any_number] : [...] } } }
 * @returns {(array|object)} - The data to work with.
 * @example
 * * // returns ["A"]
 *  getAggregationFromElasticResponse({ '\_d\_':{ aggregations:{ 2 : ["A"] } } })
 *
 * * // returns {}
 *  getAggregationFromElasticResponse()
 */
export const getAggregationFromElasticResponse = (response = {}) => {
	let r__

	if (response._de_) {
		r__ = {
			if: true,
			response: (response._d_ && response._d_.aggregations && Object.values(response._d_.aggregations || {}).pop()) || {},
			rawresponse: response
		}
		return (response._d_ && response._d_.aggregations && Object.values(response._d_.aggregations || {}).pop()) || {}
	} else if (response.aggregations) {
		r__ = {
			elif: true,
			response: (response.aggregations && Object.values(response.aggregations || {}).pop()) || {},
			rawresponse: response
		}
		return (response.aggregations && Object.values(response.aggregations || {}).pop()) || {}
	} else {
		const item = Object.values(response || {}).filter((each) => each instanceof Object && each.buckets)

		r__ = {
			else: true,
			response: item,
			rawresponse: response
		}
		// console.log('item__', item)
	}

	// console.log('r__', r__)

	return {}
}

/**
 * @param {Array} array
 * @param {object} callbacks  = {whenPresent,whenNotPresent} - Callback to perform an operation when item is present or not.
 * @param {any} item - Item to look for in the array
 * @returns {Array} - New array with/without item based on its existence
 * @example
 * * // returns ["A", "B"]
 * * toggleItemInArray(["A", "C", "B"], "C")
 *
 * * // returns ["A", "B", "D"]
 * * toggleItemInArray(["A", "B"], "D")
 */

export const toggleItemInArray = (array = [], item, callbacks = {}) => {
	const { whenPresent, whenNotPresent } = callbacks
	// Immutability
	const clonedArray = array.concat()
	const index = clonedArray.indexOf(item)

	if (index > -1) {
		typeof whenPresent === 'function' && whenPresent()
		clonedArray.splice(index, 1)
	} else {
		typeof whenNotPresent === 'function' && whenNotPresent()
		clonedArray.push(item)
	}

	return clonedArray
}

/**
 * @const {{indian: callback , international: callback }} formatWithComma
 * @desc Used to format numbers in indian / international format.
 */
export const formatWithComma = {
	INR: (number = 0, decimalPlaces = 0) => {
		number = Number(number).toFixed(decimalPlaces)
		if (number <= 0) {
			return 0
		}
		let x = String(number)
		let afterPoint = ''
		if (x.indexOf('.') > 0) {
			afterPoint = x.substring(x.indexOf('.'), x.length)
		}
		x = String(Math.floor(number))
		let lastThree = x.substring(x.length - 3)
		let otherNumbers = x.substring(0, x.length - 3)
		if (otherNumbers != '') {
			lastThree = ',' + lastThree
		}
		let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint
		return res
	},
	USD: (num, decimalPlaces = 0) => {
		num = Number(num).toFixed(decimalPlaces)
		if (num > 0) {
			num = parseFloat(num)
			num = num.toFixed(2)
			let parts = num.toString().split('.')
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			// console.log({ parts })
			return decimalPlaces <= 0 ? parts[0] : parts.join('.')
		}
	}
}

export const getFormattedPrice = (price, placesAfterDecimal = 2) => {
	return formatWithComma.INR(Number(price).toFixed(placesAfterDecimal))
}

/**
 * @desc Used for assigning unique keys to array of elements
 * @returns {string} - a uuid
 * @see https://www.npmjs.com/package/uuid
 * @example
 * * // returns b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d
 * getUuid();
 */
export const getUuid = () => uuidv4()

export const replaceByRegex = (targetString = '', replaceBy = '', regex) => {
	return targetString.length > 0 ? targetString.replace(regex, replaceBy) : targetString
}

/**
 * @desc Replaces multiple substring in a string
 * @todo Can be improvised if needed. See: https://stackoverflow.com/questions/15604140/replace-multiple-strings-with-multiple-other-strings
 * @param {string} targetString - String to look upon
 * @param {object[]} wordAndRegexPair - Array of object to replace the data
 * @param {Regex} wordAndRegexPair[].regex - Regex to match
 * @param {string} wordAndRegexPair[].replaceBy - String to replace with the matched string
 * @returns
 */
export const replaceMultipleByRegex = (targetString = '', wordAndRegexPair = []) => {
	let newString = ''

	if (wordAndRegexPair.length <= 0 || targetString.length <= 0) {
		return targetString
	}

	wordAndRegexPair.forEach((each) => {
		const { regex = / /g, replaceBy = ' ' } = each

		if (newString.length > 0) {
			newString = newString.replace(regex, replaceBy)
		} else {
			newString = targetString.replace(regex, replaceBy)
		}
	})

	return newString
}

export const rand = (min = 0, max = 100) => Math.floor(Math.random() * (max - min + 1) + min)

export const randomHSLAValuesInVisibleRange = () => [rand(0, 360), rand(50, 85), rand(35, 65)]

export const randomBgAndBorderColor = (bgAlpha = 0.6, borderAlpha = 1) => {
	const [h, s, l] = randomHSLAValuesInVisibleRange()

	return {
		bgColorRandom: `hsla(${h}deg, ${s}%, ${l}%, ${bgAlpha})`,
		borderColorRandom: `hsla(${h}deg, ${s}%, ${l}%, ${borderAlpha})`
	}
}

export const hourFormatter = (hourInInteger) => {
	if (hourInInteger <= 1) {
		return `${hourInInteger} hour`
	} else if (hourInInteger <= 23) {
		return `${hourInInteger} hours`
	} else if (hourInInteger === 24) {
		return `1 day`
	} else {
		return `${hourInInteger / 24} days`
	}
}

/**
 * @readonly
 * @const {string} alphabetsForLabels - Each alphabet is used to for label in charts because labels are too long.
 */
const alphabetsForLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

/**
 * @function A recursive function
 * @desc -
 * @param {*} config
 * @param {*} response
 * @param {*} xAxis
 * @param {*} yAxisesWithMultiLabels
 * @returns
 */

export const generateArrayofBackgroundAndBorderColors = (n = 1) => {
	if (n === 0) return []

	const existingColors = colorsForCharts
		.concat()
		.slice(0, n)
		.map((color) => ({
			borderColor: color.rgb().string(),
			backgroundColor: color.fade(0).rgb().string()
		}))

	if (n > colorsForCharts.length) {
		const restOfTheColors = []

		for (let index = 0; index < n - colorsForCharts.length; index++) {
			const { bgColorRandom, borderColorRandom } = randomBgAndBorderColor(0.55, 1)

			restOfTheColors.push({
				backgroundColor: bgColorRandom,
				borderColor: borderColorRandom
			})
		}

		return existingColors.concat(restOfTheColors)
	}

	// console.log('COLOR____', n, existingColors.length)
	return existingColors
}

export const genratePieGraphDataRecursively = (config = {}, response = {}, xAxis = [], yAxisesWithMultiLabels = {}) => {
	const { applyFunctionOnXAxis = (p) => p, ...chartJSConfig } = config

	if (response.aggregations) {
		response = getAggregationFromElasticResponse(response)
	}

	if (response && response.buckets && response.buckets.length > 0) {
		for (let index = 0; index < response.buckets.length; index++) {
			const item = response.buckets[index]

			const { bgColorRandom, borderColorRandom } = randomBgAndBorderColor()

			if (!yAxisesWithMultiLabels['0']) {
				yAxisesWithMultiLabels['0'] = {
					fill: true,
					data: [],
					labels: [], // for Radar Chart tooltip
					backgroundColor: [],
					borderColor: [],
					// hidden: true,
					...chartJSConfig
				}
			}

			let bgColor = Math.random()
			let borderColor = Math.random()

			xAxis.push(applyFunctionOnXAxis(item.key))
			const yAxis = yAxisesWithMultiLabels['0']

			yAxis.data.push(item.doc_count)
			yAxis.labels.push(item.key)

			if (typeof yAxis.backgroundColor === 'object') {
				yAxis.backgroundColor.push(bgColorRandom)
				yAxis.borderColor.push(borderColorRandom)
			}
		}

		// console.log('yAxis', chartJSConfig, yAxisesWithMultiLabels, xAxis, Object.values(yAxisesWithMultiLabels))

		return {
			labels: xAxis,
			datasetOriginalLabels: xAxis,
			datasets: Object.values(yAxisesWithMultiLabels)
		}
	}
}

export const generateLineGraphData = (config = {}, data = {}) => {
	const { applyFunctionOnXAxis = (p) => p, single = false, ...chartJSConfig } = config

	const labels = []
	const graph = {}

	if (data.aggregations) {
		data = getAggregationFromElasticResponse(data)?.buckets || []
	}

	if (single) {
		for (let j = 0; j < data.length; j++) {
			const bItem = data[j]

			if (!graph[0]) {
				graph[0] = {
					fill: true,
					uniqueKey: bItem.key,
					label: bItem.key,
					data: [],
					...chartJSConfig
				}
			}
			labels.push(bItem.key)
			graph[0]?.data?.push?.({
				x: bItem.key_as_string,
				y: bItem.doc_count
			})
		}
	} else {
		for (let index = 0; index < data.length; index++) {
			const element = data[index]

			if (element.key_as_string) {
				const bucketsList = Object.values(element).find((each) => typeof each === 'object')?.buckets

				for (let j = 0; j < bucketsList.length; j++) {
					const bItem = bucketsList[j]

					if (!graph[bItem.key]) {
						graph[bItem.key] = {
							fill: true,
							uniqueKey: bItem.key,
							label: bItem.key,
							data: [],
							...chartJSConfig
						}
					}

					graph[bItem.key]?.data?.push?.({
						x: element.key_as_string,
						y: bItem.doc_count
					})

					// return {
					//     datasets:Object.values(graph)
					// };
				}
				labels.push(element.key)
			}
		}
	}

	// return graph;
	const colors = generateArrayofBackgroundAndBorderColors(Object.values(graph).length)
	// console.log('colors___', colors)

	return {
		colors,
		labels,
		datasets: Object.values(graph).map((each, i) => ({
			...each,
			...colors[i]
		}))
	}
}

export const generateBarGraphData = (config = {}, data = {}) => {
	const { applyFunctionOnXAxis = (p) => p, single = false, ...chartJSConfig } = config

	const labels = []
	const graph = {}

	if (data.aggregations) {
		data = getAggregationFromElasticResponse(data)?.buckets || []
	}
	// console.log('data___', data)

	if (single) {
		for (let j = 0; j < data.length; j++) {
			const bItem = data[j]

			if (!graph[0]) {
				graph[0] = {
					fill: true,
					uniqueKey: bItem.key,
					data: [],
					...chartJSConfig
				}
			}
			labels.push(bItem.key)
			graph[0]?.data?.push?.({
				x: bItem.key_as_string || bItem.key,
				y: bItem.doc_count
			})
		}
	} else {
		for (let index = 0; index < data.length; index++) {
			const element = data[index]

			if (element.key_as_string || element.key) {
				const bucketsList = Object.values(element).find((each) => typeof each === 'object')?.buckets

				for (let j = 0; j < bucketsList.length; j++) {
					const bItem = bucketsList[j]

					if (!graph[bItem.key]) {
						graph[bItem.key] = {
							fill: true,
							uniqueKey: bItem.key,
							label: bItem.key,
							data: [],
							...chartJSConfig
						}
					}

					graph[bItem.key]?.data?.push?.({
						x: element.key_as_string || element.key,
						y: bItem.doc_count
					})
				}

				labels.push(element.key)
			}
		}
	}

	const colors = generateArrayofBackgroundAndBorderColors(Object.values(graph).length)
	console.log('colors___', colors)

	return {
		colors,
		labels,
		datasets: Object.values(graph).map((each, i) => ({
			...each,
			...colors[i]
		}))
	}
}

export const generatePieGraphData = (config = {}, data = {}) => {
	const { applyFunctionOnXAxis = (p) => p, ...chartJSConfig } = config
	if (data.aggregations) {
		data = getAggregationFromElasticResponse(data)?.buckets || []
	}

	const graph = {}

	for (let index = 0; index < data.length; index++) {
		const element = data[index]

		if (element.key) {
			if (!graph[element.key]) {
				graph[element.key] = element.doc_count
			}
		}
	}

	const labels = Object.keys(graph)
	const colors = generateArrayofBackgroundAndBorderColors(Object.values(graph).length)

	return {
		labels,
		datasetOriginalLabels: labels,
		colors,
		datasets: [
			{
				fill: true,
				backgroundColor: colors.map((each) => each.backgroundColor),
				borderColor: colors.map((each) => each.borderColor),
				data: Object.values(graph),
				labels,
				...chartJSConfig
			}
		]
	}
}

export const genrateGraphDataRecursivelyForScatterChart = (config = {}, response = {}, xAxis = [], yAxisesWithMultiLabels = {}) => {
	const {
		applyFunctionOnXAxis = (p) => p,
		yAxisJsonKey = 'value',
		xAxisJsonKey = 'key_as_string',
		noBg = false,
		single = false,
		...chartJSConfig
	} = config

	if (response.aggregations) {
		console.log('response__', response)

		response = getAggregationFromElasticResponse(response)
	}

	if (response && response.buckets && response.buckets.length > 0) {
		const buckets =
			response.buckets.length > 10
				? response.buckets
				: [...new Array(response.buckets.length + 4).keys()]
					.map((each) => {
						return {
							key_as_string: Object.byString(response.buckets[0], 'key_as_string'),
							key: Object.byString(response.buckets[0], 'key'),
							doc_count: 0
						}
					})
					.concat(response.buckets)

		for (let index = 0; index < (single ? buckets : response.buckets).length; index++) {
			const item = single ? buckets[index] : response.buckets[index]

			const { bgColorRandom, borderColorRandom } = randomBgAndBorderColor()

			if (item.key_as_string) {
				xAxis.push(applyFunctionOnXAxis(item.key))
				// xAxis.push(applyFunctionOnXAxis(item.key_as_string || item.key));
			} else {
				// is multiple

				if (!yAxisesWithMultiLabels[item.key]) {
					let bgColor = Math.random()
					let borderColor = Math.random()

					yAxisesWithMultiLabels[item.key] = {
						...chartJSConfig,
						// label: alphabetsForLabels[Object.keys(yAxisesWithMultiLabels).length],
						label: item.key,
						// fill: !noBg,
						data: [],
						pointRadius: rand(4, 9),
						pointHoverRadius: rand(4, 9),

						backgroundColor: bgColorRandom,
						borderColor: borderColorRandom

						// backgroundColor: `rgb(${rand(0,200)}, ${rand(0,255)}, ${rand(0,255)}, 1)`,
						// backgroundColor: `rgb(25, 19, 63, 1)`,
						// backgroundColor: [],
						// borderColor: [],
						// borderColor: `rgba(25, 19, 63, ${(borderColor <= 0.3 ? 0.25 : borderColor - 0.1).toFixed(2)})`,
					}
				}

				const yAxis = yAxisesWithMultiLabels[item.key]

				if (yAxis && yAxis.data) {
					// yAxis.backgroundColor.push(`rgb(25, 19, 63, ${(Math.random() === 0 ? 0.2 : Math.random() - 0.1).toFixed(2)})`);
					// yAxis.borderColor.push(`rgb(25, 19, 63, ${(Math.random() === 0 ? 0.2 : Math.random() - 0.1).toFixed(2)})`);

					yAxis.data.push({
						x: xAxis.concat().pop(),
						y: item.doc_count
						// r: item.doc_count > 100
						//     ? limitTheNumberBetween((Math.round(Math.sqrt(item.doc_count))), 8, 12)
						//     : item.doc_count < 40
						//         ? 2
						//         : 6
					})
				}
			}

			genrateGraphDataRecursivelyForScatterChart(
				{ applyFunctionOnXAxis, ...chartJSConfig },
				Object.values(item).find((each) => each.buckets) || {},
				xAxis,
				yAxisesWithMultiLabels
			)
		}

		return {
			// labels: xAxis,
			datasets: Object.values(yAxisesWithMultiLabels),
			datasetOriginalLabels: Object.keys(yAxisesWithMultiLabels)
		}
	}
}

export const generateScatterGraphData = (config = {}, data) => {
	const graph = {}
	const { applyFunctionOnXAxis = (p) => p, noBg = false, single = false, ...chartJSConfig } = config

	if (data.aggregations) {
		console.log('data__', data)

		data = getAggregationFromElasticResponse(data)?.buckets || []
	}

	for (let index = 0; index < data.length; index++) {
		const element = data[index]

		if (element.key_as_string) {
			const bucketsList = Object.values(element).find((each) => typeof each === 'object')?.buckets

			for (let j = 0; j < bucketsList.length; j++) {
				const bItem = bucketsList[j]

				if (!graph[bItem.key]) {
					graph[bItem.key] = {
						label: bItem.key,
						data: [],
						pointRadius: rand(4, 9),
						pointHoverRadius: rand(9, 14),

						...chartJSConfig
					}
				}

				graph[bItem.key]?.data?.push?.({
					x: element.key,
					y: bItem.doc_count
				})
			}
		}
	}

	const colors = generateArrayofBackgroundAndBorderColors(Object.values(graph).length)
	console.log('colors___', colors)

	return {
		datasets: Object.values(graph).map((each, i) => ({
			...each,
			...colors[i]
		}))
	}
}

export const mapNestedRoutes = (allRoutes = {}) => {
	const routeEntries = Object.entries(allRoutes)

	let routes = {}

	for (let index = 0; index < routeEntries.length; index++) {
		const [key, value] = routeEntries[index]

		if (value.parentRoute) {
			if (routes[value.parentRoute]) {
				const currentParentRoute = routes[value.parentRoute]

				routes[value.parentRoute] = {
					...currentParentRoute,
					[key]: value
				}
			} else {
				routes[value.parentRoute] = {
					[key]: value
				}
			}
		}
	}
	return routes
}

export const filterObjectByKeys = (objectToFilter = {}, filterKeys = []) => {
	return Object.keys(objectToFilter)
		.filter((key) => filterKeys.includes(key))
		.reduce((obj, key) => {
			obj[key] = objectToFilter[key]
			return obj
		}, {})
}

export const flattenNestedObjectsIntoArrayForES = (cols = [], response = {}, useAggregationsData = false) => {
	// Convert array to object of object.
	const keys = cols
		.map((each) => ({
			name: each.name,
			elasticConfig: each.elasticConfig
		}))
		.reduce((obj, key) => {
			obj[key.name] = key.elasticConfig

			return obj
		}, {})

	if (useAggregationsData) {
		const flattenedAggregation = flatten(response.aggregations, {
			safe: true
		})
		const flattenedAggregationAsObj = flatten(response.aggregations)

		let rows = flattenedAggregation[`${Object.keys(response.aggregations)[0]}.buckets`]

		const finalRows = rows.map((row, index) => {
			const { ...clonedKeys } = keys

			// Find value by path string.
			// Assign value to each key if exists, perform an action if given or ---
			Object.keys(keys).forEach((each) => {
				const formattedColName = each.replaceAll('[]', `.${index}`)

				clonedKeys[each] = `${flattenedAggregationAsObj[formattedColName]
					? clonedKeys[each] && clonedKeys[each].callback
						? clonedKeys[each].callback(flattenedAggregationAsObj[formattedColName])
						: flattenedAggregationAsObj[formattedColName]
					: '----'
					}`
			})

			return clonedKeys
		})

		console.log({
			finalRows,
			flattenedAggregation,
			cols,
			flattenedAggregationAsObj
		})
		return finalRows
	}

	// let arrayOfData =
	//     response ? (
	//         useAggregationsData ?  (
	//             (response.aggregations) ? response.aggregations : []
	//         ) : (
	//             (response.hits && response.hits.hits) ? response.hits.hits : []
	//         )
	//     ) : []

	let arrayOfData =
		//FEXME: NEED to make it as per new response
		response && response.hits && response.hits.hits ? response.hits.hits : []

	return arrayOfData.map((item) => {
		const { ...clonedKeys } = keys

		// Find value by path string.
		// Assign value to each key if exists, perform an action if given or ---
		Object.keys(keys).forEach((each) => {
			clonedKeys[each] = `${Object.byString(item, each)
				? clonedKeys[each] && clonedKeys[each].callback
					? clonedKeys[each].callback(Object.byString(item, each))
					: Object.byString(item, each)
				: '----'
				}`
		})

		return clonedKeys
	})
}

export const flattenNestedObjectsForESData = (cols = [], response = {}) => {
	// Convert array to object of object.
	const keys = cols
		.map((each) => ({
			name: each.name,
			elasticConfig: each.elasticConfig
		}))
		.reduce((obj, key) => {
			obj[key.name] = key.elasticConfig

			return obj
		}, {})

	const arrayOfData = getAggregationFromElasticResponse(response)?.buckets || []

	return arrayOfData.map((item) => {
		const { ...clonedKeys } = keys

		// Find value by path string.
		// Assign value to each key if exists, perform an action if given or ---
		Object.keys(keys).forEach((each) => {
			clonedKeys[each] = `${Object.byString(item, each)
				? clonedKeys[each] && clonedKeys[each].callback
					? clonedKeys[each].callback(Object.byString(item, each))
					: Object.byString(item, each)
				: '----'
				}`
		})

		return clonedKeys
	})
}

export const flattenNestedObjectsIntoArray = (cols = [], arrayOfData = []) => {
	// Convert array to object of object.
	const keys = cols
		.map((each) => ({
			name: each.name,
			elasticConfig: each.elasticConfig
		}))
		.reduce((obj, key) => {
			obj[key.name] = key.elasticConfig

			return obj
		}, {})

	return arrayOfData.map((item) => {
		const { ...clonedKeys } = keys

		// Find value by path string.
		// Assign value to each key if exists, perform an action if given or ---
		Object.keys(keys).forEach((each) => {
			clonedKeys[each] = `${Object.byString(item, each)
				? clonedKeys[each] && clonedKeys[each].callback
					? clonedKeys[each].callback(Object.byString(item, each))
					: Object.byString(item, each)
				: '----'
				}`
		})

		return clonedKeys
	})
}

// Single key
export const sumOfKeysInArrayOfObjects = (array = [], key) => {
	// ? Key is important.
	if (!key) {
		return 0
	}

	return array.reduce((sum, current) => {
		return sum + current[key]
	}, 0)
}

export const isAtLastPageInTable = (rows = 10, page = 0, total = 500, thershold = 0.7) => {
	// thershold = how early from the last page. Default 70% of total data;
	return rows * (page + 1) >= total * limitTheNumberBetween(thershold, 0.1, 1)
}

export const calculatePageForES = (from = 0, size = 500) => {
	return {
		from,
		size
	}
}

export const makePieChartFromJSONArray = (jsonArray = [], keyToLookFor = '') => {
	const colorsForCharts = [
		colorManipulator('#19133F'),
		colorManipulator('#474265'),
		colorManipulator('#BCC1DD'),
		colorManipulator('#185ADB'),
		colorManipulator('#2EC1AC'),
		colorManipulator('#FF94CC'),
		colorManipulator('#FF884B'),
		colorManipulator('#FF616D'),
		colorManipulator('#5C33F6'),
		colorManipulator('#FFC764'),
		colorManipulator('#F21170'),
		colorManipulator('#3E64FF')
	]

	// All the values found by key=`keyToLookFor` will be the `key`(labels[]) for this object
	//  and `value`(data[]) will be the number of occurence.
	let calculatedValue = {}

	for (let index = 0; index < jsonArray.length; index++) {
		const item = jsonArray[index]

		if (!item[keyToLookFor]) return {}

		const { ...initalCalculatedValue } = calculatedValue

		if (!!calculatedValue[item[keyToLookFor]]) {
			calculatedValue = {
				...initalCalculatedValue,
				[item[keyToLookFor]]: calculatedValue[item[keyToLookFor]] + 1
			}
		} else {
			calculatedValue = {
				...initalCalculatedValue,
				[item[keyToLookFor]]: 1
			}
		}
	}

	return {
		data: {
			datasets: [
				{
					backgroundColor: Object.values(calculatedValue).map((each, index) => colorsForCharts[index].rgb().string()),
					borderColor: Object.values(calculatedValue).map((each, index) => colorsForCharts[index].rgb().string()),
					data: Object.values(calculatedValue),
					fill: true
				}
			],
			labels: Object.keys(calculatedValue)
		},
		calculatedValue
	}
}

// maxLength = number of characters
export const addEllipsisToText = (string = '', maxLength = 100) => (string.length > 0 ? string.slice(0, maxLength).concat('...') : '')

// ?Converts 'user/:user_id/group/:id/role/:roleId' => 'user/1/group/21/role/3'
export const replaceVariableWithValueInURL = (url, values = []) => {
	if (!url) {
		return ''
	}

	// regex => `:anyVar1able`
	const regex = /\:[a-zA-Z0-9\_]*/

	let newUrl = url.concat('')

	for (var i = 0; i < values.length; i++) {
		const item = values[i]

		const match = regex.exec(newUrl)

		if (match === null) {
			return newUrl
		}

		newUrl = newUrl.replace(match.concat().pop(), item)
	}

	return newUrl
}

/**
 * @desc Generates random aplha-numeric string
 * @param {number} [length=8] - Length of the uuid string
 * @returns {string} - A uuid of the desired length
 * @example generateSmallUuid()
 */
// * substr(2, length) - is to eliminate '.0' in the beginning
export const generateSmallUuid = (length = 8) => Math.random().toString(36).substr(2, length)

export const timeSince = (date, integerOnly = false) => {
	// console.log("Date received:", date);
	const seconds = Math.round((new Date() - new Date(date)) / 1000)
	let interval = seconds / 31536000

	if (interval > 1) {
		return integerOnly ? Math.round(interval) : Math.round(interval) + ' years ago'
	}

	interval = seconds / 2592000
	if (interval > 1) {
		return integerOnly ? Math.round(interval) : Math.round(interval) + ' months ago'
	}

	interval = seconds / 86400
	if (interval > 1) {
		return integerOnly ? Math.round(interval) : Math.round(interval) + ' days ago'
	}

	interval = seconds / 3600
	if (interval > 1) {
		return integerOnly ? Math.round(interval) : Math.round(interval) + ' hours ago'
	}

	interval = seconds / 60
	if (interval > 1) {
		return integerOnly ? Math.round(interval) : Math.round(interval) + ' minutes ago'
	}

	return integerOnly ? Math.round(seconds) : Math.round(seconds) + ' seconds ago'
}

export const sortDateByISODate = (data = [], dateJsonkey = 'created_on') => {
	const newData = data.concat()

	newData.sort(function (a, b) {
		// Descending order
		return b[dateJsonkey].localeCompare(a[dateJsonkey])
	})

	return newData
}

// Convert File Object to bindary.
export const getBinaryFromFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.addEventListener('load', () => resolve(reader.result))
		reader.addEventListener('error', (err) => reject(err))

		reader.readAsBinaryString(file)
	})
}

// Convert byte integers to MB, GB, etc
export const formatBytes = (bytes = 0, decimals = 2) => {
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const handleProgressOnUpload =
	(onStart = () => { }, item = {}, currentState = {}, onFinish = () => { }) =>
		(progressEvent) => {
			const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

			if (onFinish && progress >= 99) {
				onFinish(progress, progressEvent, item, currentState)
			}

			onStart(progress, progressEvent, item, currentState)
		}

export const loadScript = (url, id) => {
	if (!id) {
		id = generateSmallUuid()
	}

	return new Promise((resolve) => {
		const existingScript = document.getElementById(id)

		if (!!existingScript) resolve(true)
		if (!url) resolve(false)

		const script = document.createElement('script')

		script.src = url
		script.async = true
		script.id = id

		script.onload = () => {
			resolve(true)
		}

		script.onerror = () => {
			resolve(false)
		}

		document.body.appendChild(script)
	})
}

// FUTURE: Need to implement https://snowplowanalytics.com/ here
// NOTE: Create Seperate file when using more than one function for analytics. Else ignore
export const logGAEvent = (actionName, options = {}) => {
	if (!(window && typeof window.gtag === 'function')) return

	if (!actionName)
		throw new Error('action name is missing. See: https://developers.google.com/analytics/devguides/collection/gtagjs/events')
	if (!(optionsByAction[actionName] || options.event_category))
		throw new Error('event category is missing. See https://developers.google.com/analytics/devguides/collection/gtagjs/events')
	if (!options.event_label)
		throw new Error('event label is missing. See https://developers.google.com/analytics/devguides/collection/gtagjs/events')

	// Helpful when handling forms
	// SEE: https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data?hl=en
	// const { event_callback = () => {} } = options;

	window.gtag('event', actionName, {
		...optionsByAction[actionName],
		...options
	})
}

export const seperate = (string = '', limiter = '.') => {
	let arr, suffix, prefix

	arr = string = ''.split(limiter)
	suffix = arr.pop(arr.length - 1)
	prefix = arr.join(limiter)

	return [prefix, suffix]
}

// TODO: Solve
export const traverseChildToParentRoute = (childRouteName, routeObject, tree = {}) => {
	if (Object.keys(routeObject) === Object.keys(tree)) return tree

	const item = routeObject[childRouteName]

	const initialTree = { ...tree }

	if (item.parentRoute) {
		tree[childRouteName] = {}
	} else {
		tree = {
			...initialTree,
			[childRouteName]: null
		}
	}
}

// Why it returns object? Because it will be easier to display in HTML. E.g - <p>{num}<sup>{suffix}</sup></p>
export const getNumberWithOrdinalSuffixAsObject = (i) => {
	var j = i % 10,
		k = i % 100
	if (j == 1 && k != 11) {
		return {
			num: i,
			suffix: 'st'
		}
	}
	if (j == 2 && k != 12) {
		return {
			num: i,
			suffix: 'nd'
		}
	}
	if (j == 3 && k != 13) {
		return {
			num: i,
			suffix: 'rd'
		}
	}
	return {
		num: i,
		suffix: 'th'
	}
}

// Why it returns object? Because it will be easier to display in HTML. <sup>{suffix}</sup>
export const getDateWithOrdinalAsObject = (date) => {
	date = !!date ? new Date(date) : new Date()

	const [day, month, year] = [date.getDate(), date.getMonth() + 1, date.getFullYear()]

	const { num, suffix } = getNumberWithOrdinalSuffixAsObject(day)

	return { day: num, suffix, month, year }
}

export const getAbsoluteHeight = (el, pseudoElement) => {
	// Get the DOM Node if you pass in a string
	el = typeof el === 'string' ? document.querySelector(el) : el

	if (!el) {
		return 0
	}

	var styles = pseudoElement ? window.getComputedStyle(el, pseudoElement) : window.getComputedStyle(el)
	var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])

	return Math.ceil(el.offsetHeight + margin)
}

export const removeMultipleFromObjects = (object, keys) =>
	keys.reduce((o, k) => {
		const { [k]: _, ...p } = o
		return p
	}, object)

export const getAvailableHeight = (el) => {
	// Get the DOM Node if you pass in a string
	el = typeof el === 'string' ? document.querySelector(el) : el

	if (!el) return 0

	var styles = window.getComputedStyle(el)

	let pseudoBeforeStyles = window.getComputedStyle(el, ':before')

	let pseudoBeforeHeight =
		Number(String(pseudoBeforeStyles['marginTop']).replace(/px/g, '')) +
		Number(String(pseudoBeforeStyles['marginBottom']).replace(/px/g, '')) +
		Number(String(pseudoBeforeStyles['height']).replace(/px/g, ''))

	console.log(
		'PAGE-getAvailableHeight-before',
		Number(String(pseudoBeforeStyles['marginTop']).replace(/px/g, '')),
		Number(String(pseudoBeforeStyles['marginBottom']).replace(/px/g, '')),
		Number(String(pseudoBeforeStyles['height']).replace(/px/g, ''))
	)

	let pseudoAfterStyles = window.getComputedStyle(el, ':after')

	let pseudoAfterHeight =
		Number(String(pseudoAfterStyles['marginTop']).replace(/px/g, '')) +
		Number(String(pseudoAfterStyles['marginBottom']).replace(/px/g, '')) +
		Number(String(pseudoAfterStyles['height']).replace(/px/g, ''))

	console.log(
		'PAGE-getAvailableHeight-after',
		Number(String(pseudoAfterStyles['marginTop']).replace(/px/g, '')),
		Number(String(pseudoAfterStyles['marginBottom']).replace(/px/g, '')),
		Number(String(pseudoAfterStyles['height']).replace(/px/g, ''))
	)

	var margin = parseFloat(styles['paddingTop']) + parseFloat(styles['paddingBottom'])

	console.log('PAGE-getAvailableHeight-margin', parseFloat(styles['paddingTop']), parseFloat(styles['paddingBottom']))

	return Math.ceil(el.offsetHeight - (margin + pseudoBeforeHeight + pseudoAfterHeight))
}

export const nativeAppxDurationBetweenDates = (startDate = new Date(), endDate = new Date()) => {
	if (!(startDate instanceof Date)) return -1
	if (!(endDate instanceof Date)) return -2

	const oneMinuteInMS = 60 * 1000
	const oneHourInMS = 60 * oneMinuteInMS
	const oneDayInMS = 24 * oneHourInMS

	const startDateMS = startDate.getTime()
	const endDateMS = endDate.getTime()

	const diffInMS = endDateMS - startDateMS

	if (diffInMS > oneDayInMS) return { diff: Math.round(diffInMS / oneDayInMS), unit: 'day' }

	if (diffInMS > oneHourInMS) return { diff: Math.round(diffInMS / oneHourInMS), unit: 'hour' }

	if (diffInMS > oneMinuteInMS) return { diff: Math.round(diffInMS / oneMinuteInMS), unit: 'minute' }

	return 0
}

export const convertBooleanToYesOrNo = (bool, inverse) => {
	if (!inverse) return bool === true ? 'yes' : 'no'

	return bool === 'yes'
}

export const downloadUrl = (dataurl, filename) => {
	const link = document.createElement('a')
	link.href = dataurl
	link.target = '_blank'
	link.download = filename
	link.click()

	console.log({ dataurl, filename })
}

export const getTimestampDifference = (startDate, endDate) => {
	startDate = new Date(startDate)
	endDate = new Date(endDate)

	const difference = endDate - startDate

	return Object.entries(intervalTypes).reduce((obj, [key, item]) => {
		obj[key] = {
			name: item.name,
			value: Math.floor(difference / item.unit)
		}

		return obj
	}, {})
}

export const calculateFixedInterval = (startDate, endDate) => {
	const differenceByUnits = getTimestampDifference(startDate, endDate)

	if (differenceByUnits.HOUR.value <= 12) {
		return { fixed_interval: `30${differenceByUnits.MINUTE.name}` }
	} else if (differenceByUnits.HOUR.value > 12 && differenceByUnits.DAY.value <= 4) {
		return { fixed_interval: `1${differenceByUnits.HOUR.name}` }
	} else if (differenceByUnits.DAY.value > 4 && differenceByUnits.MONTH.value <= 1) {
		return { fixed_interval: `1${differenceByUnits.DAY.name}` }
	} else if (differenceByUnits.MONTH.value > 1 && differenceByUnits.MONTH.value <= 6) {
		return { fixed_interval: `7${differenceByUnits.DAY.name}` }
	}
	else if (differenceByUnits.MONTH.value > 6 && differenceByUnits.YEAR.value <= 5) {
		return { fixed_interval: `90${differenceByUnits.DAY.name}` }
	} else if (differenceByUnits.YEAR.value > 5) {
		// Equal to 1 quarter
		return { fixed_interval: `180${differenceByUnits.DAY.name}` }
	}
}

export const extractDataFromResponse = (response = {}) => {
	if (response.aggregations) {
		response = (response.aggregations && Object.values(response.aggregations).pop()) || {}

		if (response.buckets) {
			response = response.buckets || []
		} else {
			response = []
		}
	}

	return response
}

export const generatePieData = (response = {}) => {
	response = extractDataFromResponse(response)

	const colors = colorsForCharts
		.concat()
		.slice(0, response.length)
		.map((each) => each.fade(0).rgb().toString())
	const borderColors = colorsForCharts
		.concat()
		.slice(0, response.length)
		.map((each) => each.rgb().toString())

	return response.map((item, index) => ({
		id: item.key,
		label: item.key,
		value: item.doc_count,
		color: colors[index],
		borderColor: borderColors[index],
		pointTableData: response
	}))
}

export const generatePieDataUpdated = (response = {}) => {
	response = extractDataFromResponse(response)

	// Assuming colorsForCharts is an array of objects that can call fade() and rgb()
	let colors = colorsForCharts.concat()
	let borderColors = colorsForCharts.concat()

	const responseLength = response.length

	// Extend colors and borderColors arrays if there are more items in the response than colors available
	while (colors.length < responseLength) {
		// Generate a random color in RGB format
		const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
			Math.random() * 256
		)})`

		// Push the same color for both the faded and non-faded versions for simplicity
		// Consider implementing a fade effect if necessary
		colors.push({ rgb: () => randomColor })
		borderColors.push({ rgb: () => randomColor })
	}

	// Convert color objects to string representations
	colors = colors.slice(0, responseLength).map((each) => each.rgb().toString())
	borderColors = borderColors.slice(0, responseLength).map((each) => each.rgb().toString())

	return response.map((item, index) => ({
		id: item.key,
		label: item.key,
		value: item.doc_count,
		color: colors[index],
		borderColor: borderColors[index],
		pointTableData: response
	}))
}

export const generateSankeyData = (response = {}) => {
	response = extractDataFromResponse(response)
	const graph = {}
	console.log({
		response
	})
}

export const generateLineData = (response = {}) => {
	response = extractDataFromResponse(response)

	const graph = {}

	for (let index = 0; index < response.length; index++) {
		const element = response[index]

		if (element.key_as_string) {
			const bucketsList = Object.values(element).find((each) => typeof each === 'object')?.buckets

			if (!bucketsList) {
				if (!graph[0]) {
					graph[0] = {
						id: `${index}`,
						data: []
					}
				}
				graph[0]?.data?.push?.({
					x: new Date(element.key_as_string),
					y: element.doc_count
				})
			} else {
				for (let j = 0; j < bucketsList.length; j++) {
					const bItem = bucketsList[j]

					if (!graph[bItem.key]) {
						graph[bItem.key] = {
							id: bItem.key,
							data: []
						}
					}

					graph[bItem.key]?.data?.push?.({
						x: new Date(element.key_as_string),
						y: bItem.doc_count
					})
				}
			}
		} else {
			if (!graph[0]) {
				graph[0] = {
					id: `${index}`,
					data: []
				}
			}
			graph[0]?.data?.push?.({
				x: new Date(element.key),
				y: element.doc_count
			})
		}
	}
	return Object.values(graph).map((each, i) => ({
		...each,
		color: colorsForCharts[i]?.fade?.(0)?.rgb?.()?.toString?.() || getRandomColor().rgb().toString()
	}))
}

export const generateScatterData = (response = {}) => {
	response = extractDataFromResponse(response)

	const graph = {}
	let min = -1
	let max = -1

	for (let index = 0; index < response.length; index++) {
		const element = response[index]

		if (element.key_as_string) {
			const bucketsList = Object.values(element).find((each) => typeof each === 'object')?.buckets

			if (!bucketsList) {
				if (!graph[0]) {
					graph[0] = {
						id: `${index}`,
						data: []
					}
				}
				graph[0]?.data?.push?.({
					x: new Date(element.key_as_string),
					y: element.doc_count
				})
			} else {
				for (let j = 0; j < bucketsList.length; j++) {
					const bItem = bucketsList[j]

					if (!graph[bItem.key]) {
						graph[bItem.key] = {
							id: bItem.key,
							data: []
						}
					}

					graph[bItem.key]?.data?.push?.({
						x: new Date(element.key_as_string),
						y: bItem.doc_count,
						meta: {
							agent_name: bItem.agent_name
						}
					})

					if (max === -1 || bItem.doc_count < min) {
						min = bItem.doc_count
					}

					if (max === -1 || bItem.doc_count > max) {
						max = bItem.doc_count
					}
				}
			}
		} else {
			if (!graph[0]) {
				graph[0] = {
					id: `${index}`,
					data: []
				}
			}
			graph[0]?.data?.push?.({
				x: new Date(element.key),
				y: element.doc_count
			})
		}
	}

	const colors = Object.keys(graph).reduce(
		(obj, item, i) => ({
			...obj,
			[item]: colorsForCharts[i]?.fade?.(0)?.rgb?.()?.toString?.()
		}),
		{}
	)

	console.log('->graph', graph)

	return {
		data: Object.values(graph),
		colors,
		minMax: [min, max]
	}
}

// Can be used to generate RADAR data
export const generateBarAndRadarData = (response = {}) => {
	response = extractDataFromResponse(response)

	const graph = {}
	const labels = []
	const allZeros = {}
	const colors = {}

	for (let index = 0; index < response.length; index++) {
		let graphItem

		const element = response[index]
		element.key = isDate(element.key) ? formatDateTime(element.key) : element.key

		allZeros[element.key] = 0

		if (!colors[`_${element.key}Color`]) {
			colors[`_${element.key}Color`] = colorsForCharts[Object.keys(colors).length]?.rgb?.()?.toString?.()
		}

		graphItem = {
			x: element.key,
			[element.key]: element.doc_count,
			id: index
		}

		labels.push(element.key)

		graph[element.key] = {
			...allZeros,
			...graph[element.key],
			...colors,
			[element.key]: element.doc_count,
			x: element.key,
			pointTableData: element
		}
	}

	// console.log('gData::FUNC', response, {
	// 	keys: labels,
	// 	data: Object.values(graph).map((e) => ({ ...allZeros, ...e }))
	// })

	return {
		keys: labels,
		data: Object.values(graph).map((e) => ({ ...allZeros, ...e }))
	}
}

const generateRandomColor = () => {
	// Generates a random RGB color
	const r = Math.floor(Math.random() * 256)
	const g = Math.floor(Math.random() * 256)
	const b = Math.floor(Math.random() * 256)
	return `rgb(${r},${g},${b})`
}

export const generateBarAndRadarDataUpdated = (response = {}) => {
	response = extractDataFromResponse(response)

	const graph = {}
	const labels = []
	const allZeros = {}
	const colors = {}
	const colorsForChartsLength = colorsForCharts.length // Assuming colorsForCharts is an array

	for (let index = 0; index < response.length; index++) {
		const element = response[index]
		element.key = isDate(element.key) ? formatDateTime(element.key) : element.key

		allZeros[element.key] = 0

		// Calculate the color index using modulo to cycle through colors
		const colorIndex = index % colorsForChartsLength
		colors[`_${element.key}Color`] = colorsForCharts[colorIndex].rgb().toString()

		// Construct the graph item
		const graphItem = {
			x: element.key,
			[element.key]: element.doc_count,
			id: index,
			...colors
		}

		labels.push(element.key)

		graph[element.key] = {
			...allZeros,
			[element.key]: element.doc_count,
			x: element.key,
			pointTableData: element,
			...graphItem
		}
	}

	return {
		keys: labels,
		data: Object.values(graph).map((e) => ({ ...allZeros, ...e }))
	}
}

export const generateRadarData = (response = {}) => {
	response = extractDataFromResponse(response)

	const graph = {}
	const labels = []
	const allZeros = {}
	const colors = {}

	for (let index = 0; index < response.length; index++) {
		let graphItem

		const element = response[index]

		allZeros[element.key] = 0

		if (!colors[`_${element.key}Color`]) {
			colors[`_${element.key}Color`] = colorsForCharts[Object.keys(colors).length]?.rgb?.()?.toString?.()
		}

		graphItem = {
			x: element.key,
			[element.key]: element.doc_count,
			id: index
		}

		labels.push(element.key)

		graph[element.key] = {
			...allZeros,
			...graph[element.key],
			...colors,
			[element.key]: element.doc_count,
			x: element.key
		}
	}

	// console.log('gData::FUNC', response, {
	// 	keys: labels,
	// 	data: Object.values(graph).map((e) => ({ ...allZeros, ...e }))
	// })

	return {
		keys: labels,
		data: Object.values(graph).map((e) => ({ ...allZeros, ...e }))
	}
}

export const generateGroupedBarData = (response = {}) => {
	response = extractDataFromResponse(response)

	const graph = {}
	const allZeros = {}
	const colors = {}
	const labels = []

	for (let index = 0; index < response.length; index++) {
		let graphItem

		const element = response[index]

		if (!graph[element.key]) {
			graph[element.key] = {}
		}

		const bucketsList = Object.values(element).find((each) => typeof each === 'object')?.buckets

		if (bucketsList) {
			for (let index = 0; index < bucketsList.length; index++) {
				const bItem = bucketsList[index]

				if (labels.indexOf(bItem.key) < 0) {
					labels.push(bItem.key)
				}

				allZeros[bItem.key] = 0

				if (!colors[`_${bItem.key}Color`]) {
					colors[`_${bItem.key}Color`] = colorsForCharts[Object.keys(colors).length]?.rgb?.()?.toString?.()
				}

				graph[element.key] = {
					...allZeros,
					...graph[element.key],
					...colors,
					[bItem.key]: bItem.doc_count,
					x: element.key
				}
			}
		}
	}
	return {
		keys: labels,
		data: Object.values(graph)
	}
}

export const getSeverityNameFromLevelQual = (severityValue) => {
	const value = Number(severityValue)
	if (value > severityLevelsQual.LOW.greaterThan && value <= severityLevelsQual.LOW.lessThanOrEqual) {
		return { label: severityByStatus.Low, value, color: severityColors.LOW }
	} else if (value > severityLevelsQual.MEDIUM.greaterThan && value <= severityLevelsQual.MEDIUM.lessThanOrEqual) {
		return {
			label: severityByStatus.Medium,
			value,
			color: severityColors.MEDIUM
		}
	} else if (value > severityLevelsQual.HIGH.greaterThan && value <= severityLevelsQual.HIGH.lessThanOrEqual) {
		return {
			label: severityByStatus.High,
			value,
			color: severityColors.CRITICAL
		}
	} else if (value > severityLevelsQual.CRITICAL.greaterThan && value <= severityLevelsQual.CRITICAL.lessThanOrEqual) {
		return {
			label: severityByStatus.Critical,
			value,
			color: severityColors.CRITICAL
		}
	} else if (value > severityLevelsQual.INFO.greaterThan && value <= severityLevelsQual.INFO.lessThanOrEqual) {
		return { label: severityByStatus.Info, value, color: severityColors.INFO }
	}
	// Default case if no condition is met
	return { label: 'Unknown', value, color: 'grey' }
}

export const getSeverityNameFromLevels = (severityValue) => {
	const value = Number(severityValue)
	if (value > severityLevel.LOW.greaterThan && value <= severityLevel.LOW.lessThanOrEqual) {
		return { label: severityByStatus.Low, value, color: severityColors.LOW }
	} else if (value > severityLevel.MEDIUM.greaterThan && value <= severityLevel.MEDIUM.lessThanOrEqual) {
		return {
			label: severityByStatus.Medium,
			value,
			color: severityColors.MEDIUM
		}
	} else if (value > severityLevel.HIGH.greaterThan && value <= severityLevel.HIGH.lessThanOrEqual) {
		return {
			label: severityByStatus.High,
			value,
			color: severityColors.CRITICAL
		}
	} else if (value > severityLevel.CRITICAL.greaterThan && value <= severityLevel.CRITICAL.lessThanOrEqual) {
		return {
			label: severityByStatus.Critical,
			value,
			color: severityColors.CRITICAL
		}
	} else if (value > severityLevel.INFO.greaterThan && value <= severityLevel.INFO.lessThanOrEqual) {
		return { label: severityByStatus.Info, value, color: severityColors.INFO }
	}
	// Default case if no condition is met
	return { label: 'Unknown', value, color: 'grey' }
}

export const getSeverityNameFromLevel = (severityValue) => {
	const value = Number(severityValue)
	// console.log('VALUE OF SEVERITY', value)
	if (value > severityLevels.LOW.greaterThan && value <= severityLevels.LOW.lessThanOrEqual) {
		return { label: severityByStatus.Low, value, color: severityColors.LOW }
	} else if (value > severityLevels.MEDIUM.greaterThan && value <= severityLevels.MEDIUM.lessThanOrEqual) {
		return {
			label: severityByStatus.Medium,
			value,
			color: severityColors.MEDIUM
		}
	} else if (value > severityLevels.CRITICAL.greaterThan && value <= severityLevels.CRITICAL.lessThanOrEqual) {
		return {
			label: severityByStatus.Critical,
			value,
			color: severityColors.CRITICAL
		}
	} else if (
		value > severityLevels.HIGH.greaterThan
		// && value <= severityLevels.HIGH.lessThanOrEqual
	) {
		return {
			label: severityByStatus.High,
			value,
			color: severityColors.HIGH
		}
	} else {
		return {
			label: severityByStatus.UnKnown,
			value,
			color: severityColors.HIGH
		}
	}
	// return {
	// 	label: severityByStatus.High,
	// 	value,
	// 	color: severityColors.HIGH
	// }
}

export const debounce = (func, wait, immediate) => {
	// 'private' variable for instance
	// The returned function will be able to reference this due to closure.
	// Each call to the returned function will share this common timer.
	var timeout

	// Calling debounce returns a new anonymous function
	return function () {
		// reference the context and args for the setTimeout function
		var context = this,
			args = arguments

		// Should the function be called now? If immediate is true
		//   and not already in a timeout then the answer is: Yes
		var callNow = immediate && !timeout

		// This is the basic debounce behaviour where you can call this
		//   function several times, but it will only execute once
		//   [before or after imposing a delay].
		//   Each time the returned function is called, the timer starts over.
		clearTimeout(timeout)

		// Set the new timeout
		timeout = setTimeout(function () {
			// Inside the timeout function, clear the timeout variable
			// which will let the next execution run when in 'immediate' mode
			timeout = null

			// Check if the function already ran with the immediate flag
			if (!immediate) {
				// Call the original function with apply
				// apply lets you define the 'this' object as well as the arguments
				//    (both captured before setTimeout)
				func.apply(context, args)
			}
		}, wait)

		// Immediate mode and no wait timer? Execute the function..
		if (callNow) func.apply(context, args)
	}
}

export const getMillisecondsByUnit = (value = 1, unit) => {
	switch (unit) {
		case 'hour':
			return value * 60 * 60 * 1000

		case 'seconds':
			return value * 1000

		case 'minute':
			return value * 60 * 1000

		case 'day':
			return value * 24 * 60 * 60 * 1000

		default:
			return 0
	}
}

export const sleep = (milliseconds) => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const getAccentColorBySeverityScore = (score = 0) => {
	score = parseFloat(score.toFixed(1))

	if (score >= 0 && score <= 3.9) return 'green'
	else if (score >= 4 && score <= 6.9) return 'lightAmber'
	else if (score >= 7 && score <= 8.9) return 'lightRed'
	else return 'red'
}

export const getAccentColorBySeverityScoreOverview = (score = 0) => {
	score = parseFloat(score.toFixed(1))

	if (score >= 0.1 && score < 4) return 'green'
	else if (score >= 4 && score < 7) return 'lightAmber'
	else if (score >= 7 && score < 9) return 'lightRed'
	else return 'red'
}

export const formatCount = (count = 0) => {
	if (count < 1e3) return count
	if (count >= 1e3 && count < 1e6) return +(count / 1e3).toFixed(1) + 'K'
	if (count >= 1e6 && count < 1e9) return +(count / 1e6).toFixed(1) + 'M'
	if (count >= 1e9 && count < 1e12) return +(count / 1e9).toFixed(1) + 'B'
	if (count >= 1e12) return +(count / 1e12).toFixed(1) + 'T'
}

export const formatAsPercentage = (value = 0, decimalPlaces = 2, multiplyBy = 100) => {
	return `${(value * multiplyBy).toFixed(decimalPlaces)}%`
}

export const getColorNameByRiskValue = (risk) => {
	if (risk <= 0.33) {
		return 'green'
	}
	if (risk <= 0.66) {
		return 'amber'
	}
	return 'red'
}

export const Base64 = {
	_keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
	encode: function (e) {
		var t = ''
		var n, r, i, s, o, u, a
		var f = 0
		e = Base64._utf8_encode(e)
		while (f < e.length) {
			n = e.charCodeAt(f++)
			r = e.charCodeAt(f++)
			i = e.charCodeAt(f++)
			s = n >> 2
			o = ((n & 3) << 4) | (r >> 4)
			u = ((r & 15) << 2) | (i >> 6)
			a = i & 63
			if (isNaN(r)) {
				u = a = 64
			} else if (isNaN(i)) {
				a = 64
			}
			t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
		}
		return t
	},
	decode: function (e) {
		var t = ''
		var n, r, i
		var s, o, u, a
		var f = 0
		e = e.replace(/[^A-Za-z0-9\+\/\=]/g, '')
		while (f < e.length) {
			s = this._keyStr.indexOf(e.charAt(f++))
			o = this._keyStr.indexOf(e.charAt(f++))
			u = this._keyStr.indexOf(e.charAt(f++))
			a = this._keyStr.indexOf(e.charAt(f++))
			n = (s << 2) | (o >> 4)
			r = ((o & 15) << 4) | (u >> 2)
			i = ((u & 3) << 6) | a
			t = t + String.fromCharCode(n)
			if (u != 64) {
				t = t + String.fromCharCode(r)
			}
			if (a != 64) {
				t = t + String.fromCharCode(i)
			}
		}
		t = Base64._utf8_decode(t)
		return t
	},
	_utf8_encode: function (e) {
		e = e.replace(/\r\n/g, '\n')
		var t = ''
		for (var n = 0; n < e.length; n++) {
			var r = e.charCodeAt(n)
			if (r < 128) {
				t += String.fromCharCode(r)
			} else if (r > 127 && r < 2048) {
				t += String.fromCharCode((r >> 6) | 192)
				t += String.fromCharCode((r & 63) | 128)
			} else {
				t += String.fromCharCode((r >> 12) | 224)
				t += String.fromCharCode(((r >> 6) & 63) | 128)
				t += String.fromCharCode((r & 63) | 128)
			}
		}
		return t
	},
	_utf8_decode: function (e) {
		var t = ''
		var n = 0
		var r = 0
		var c1 = 0
		var c2 = 0
		var c3 = 0
		while (n < e.length) {
			r = e.charCodeAt(n)
			if (r < 128) {
				t += String.fromCharCode(r)
				n++
			} else if (r > 191 && r < 224) {
				c2 = e.charCodeAt(n + 1)
				t += String.fromCharCode(((r & 31) << 6) | (c2 & 63))
				n += 2
			} else {
				c2 = e.charCodeAt(n + 1)
				c3 = e.charCodeAt(n + 2)
				t += String.fromCharCode(((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
				n += 3
			}
		}
		return t
	}
}

export const getParameterByName = (name, url = window.location.href) => {
	name = name.replace(/[\[\]]/g, '\\$&')
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const lineCustomTooltip = ({ slice }) => {
	return (
		<ChartCustomTooltipWrapper>
			{slice.points.map((point) => (
				// console.log('point', point)
				<Box key={point.id}>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<Text> Total Events : </Text>
						<Text fontWeight={600} fontSize={18}>
							{point.data.yFormatted}
						</Text>
					</Box>
					{moment(point.data.xFormatted) && (
						<Text margin='0 0 0.20rem 0'>{moment(point.data.xFormatted).format(DEFAULT_TIMESTAMP_FORMAT)}</Text>
					)}
				</Box>
			))}
		</ChartCustomTooltipWrapper>
	)
}

export const generateInvisibleColumnOption = (key) => ({
	label: key.replace('_source.', ''),
	value: key,
	id: key,
	name: key,
	header: key.replace('_source.', ''),
	sortkey: key.replace('_source.', '')
})

export const getCloudProviderLabelByKey = (key) => {
	const mapping = {
		aws: 'AWS',
		azure: 'Azure',
		gcp: 'GCP'
	}
	return mapping[key] || 'Unknown'
}

export const removeSelectedObjFromArr = (array, prop) => {
	return array?.filter((obj, index, self) => index === self.findIndex((item) => item[prop] === obj[prop]))
}
export const removeDuplicatesFromSelected = (optionsArr, selectedArr) => {
	return optionsArr?.filter((optionObj) => !selectedArr.some((selectedObj) => selectedObj?.id === optionObj?.id))
}

export const getOffice365MappingByUserType = (userType) => {
	const mapping = {
		0: {
			label: 'Regular',
			value: 'A regular user.'
		},
		1: {
			label: 'Reserved',
			value: 'A reserved user.'
		},
		2: {
			label: 'Admin',
			value: 'An administrator.'
		},
		3: {
			label: 'DcAdmin',
			value: 'A Microsoft datacenter operator.'
		},
		4: {
			label: 'System',
			value: 'A system account.'
		},
		5: {
			label: 'Application',
			value: 'An application.'
		},
		6: {
			label: 'ServicePrincipal',
			value: 'A service principal.'
		},
		7: {
			label: 'CustomPolicy',
			value: 'A custom policy.'
		},
		8: {
			label: 'SystemPolicy',
			value: 'A system policy.'
		}
	}

	return mapping[userType]
}

export const getMandateMappingWithIso = (key = '') => {
	return (
		{
			[mandateKeyTypes.PAPG.queryRule]: 'ISO 27001',
			[mandateKeyTypes.SAR.queryRule]: 'ISO 27001:2022'
		}[key.toLowerCase()] || 'ISO 27001'
	)
}

export const getHiddenKeysForRowDetailsByIntegrationRuleGroup = (rule_group) => {
	return (
		{
			[INTEGRATION_RULE_GROUPS.CORTEX]: [
				'cluster.node',
				'cluster.name',
				'agent.name',
				'agent.id',
				'predecoder.program_name',
				'@timestamp',
				'full_log',
				'location',
				'rule.mail',
				'data.FUTURE_USE'
			],
			[INTEGRATION_RULE_GROUPS.OFFICE365]: [
				'cluster.node',
				'cluster.name',
				'agent.name',
				'agent.id',
				'predecoder.program_name',
				'@timestamp',
				'full_log',
				'location',
				'rule.mail'
			],
			[INTEGRATION_RULE_GROUPS.CLOUDFLARE]: [
				'cluster.node',
				'cluster.name',
				'agent.name',
				'agent.id',
				'predecoder.program_name',
				'@timestamp',
				'full_log',
				'location',
				'rule.mail'
			],
			[INTEGRATION_RULE_GROUPS.PRISMA]: [
				'cluster.node',
				'cluster.name',
				'agent.name',
				'agent.id',
				'predecoder.program_name',
				'@timestamp',
				'full_log',
				'location',
				'rule.mail'
			],
			[INTEGRATION_RULE_GROUPS.BITDEFENDER]: [
				'cluster.node',
				'cluster.name',
				'agent.name',
				'agent.id',
				'predecoder.program_name',
				'@timestamp',
				'full_log',
				'location',
				'rule.mail'
			]
		}[rule_group] || []
	)
}

export const openSearchTimestampRangeByTimeRange = (timeRange) => {
	return {
		gte: timeRange?.startDate ? timeRange.startDate : moment().subtract(24, 'hours').toISOString(),
		lte: timeRange?.endDate ? timeRange.endDate : moment().toISOString()
	}
}

export const getCortexOsType = (type) => {
	return (
		{
			1: 'Windows',
			2: 'OS X/macOS',
			3: 'Android',
			4: 'Linux'
		}[type] || type
	)
}

export const prettifyCamelCase = (str) => {
	let output = ''
	let len = str.length
	let char

	for (let i = 0; i < len; i++) {
		char = str.charAt(i)

		if (i == 0) {
			output += char.toUpperCase()
		} else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
			output += ' ' + char
		} else if (char == '-' || char == '_') {
			output += ' '
		} else {
			output += char
		}
	}

	return output
}

export const fileIsValid = (
	file,
	options = {
		allowedExtentions: [],
		maxSizeInMb: 0
	}
) => {
	let isValid = false,
		errorMessage = null

	const fileSize = file.size / 1024 / 1024

	const splittedFileName = file.name.split('.')

	const fileExtention = splittedFileName[splittedFileName.length - 1]?.toLowerCase()

	const allowedExtentions = options.allowedExtentions.map((each) => each.replace('.', '').toLowerCase())

	console.log({ fileExtention, allowedExtentions })

	if (fileSize <= options.maxSizeInMb) {
		if (allowedExtentions.includes(fileExtention)) {
			isValid = true
		} else {
			errorMessage = `${file.name} was not uploaded. Reason: Invalid file format!`
		}
	} else {
		errorMessage = `${file.name} was not uploaded. Reason: File size has exceeded!`
	}

	return {
		isValid,
		errorMessage
	}
}

/**
 * This function is used to get a mapped status as a readble string
 * @param {*} value The current status value
 * @returns Current status as readable text
 */
export const getZinStatus = (value) => {
	if (value == 0 || typeof value === 'undefined') {
		return 'Upload a Document'
	} else if (value == 1) {
		return 'Document being reviewed by ZIN'
	} else if (value == 2) {
		return 'Suggestions by ZIN'
	} else if (value == 3) {
		return 'Congrats your document is ready to be Published!'
	} else {
		// This is a default message for fallback, if nothing matches
		return 'Document being reviewed by ZIN'
	}
}

/**
 * Convert the ldap time to js date object
 * @param {*} ldapTimestamp Epoch timestamp
 * @returns Date object
 */
export const ldapToJSDate = (timestamp) => {
	const FILETIME_UNIX_OFFSET = 11644473600000 // Difference in milliseconds between 1601 and 1970
	const HUNDRED_NANOSECONDS_PER_MILLISECOND = 10000 // 100 nanoseconds = 0.0001 milliseconds

	// Convert the 18-digit timestamp to a number
	const timestampNumber = parseInt(timestamp, 10)

	// Calculate the number of milliseconds since January 1, 1601
	const millisecondsSince1601 = timestampNumber / HUNDRED_NANOSECONDS_PER_MILLISECOND

	// Calculate the Unix timestamp (milliseconds since January 1, 1970)
	const unixTimestamp = millisecondsSince1601 - FILETIME_UNIX_OFFSET

	// Create a JavaScript Date object
	return new Date(unixTimestamp)
}

/**
 * Returns true if date is present or future and false if the date is past
 * @param {*} momentDateObj Momentjs date obj which will be validated
 */
export const validateDateIsPresentOrFuture = (momentDateObj) => {
	const isPastDate = momentDateObj.startOf('day').isBefore(moment()) && !momentDateObj.isSame(moment(), 'day')
	return !isPastDate
}
